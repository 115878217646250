<template>
<sdPageHeader title="Create user">
    <sdButton class="btn-add_new" size="default" key="1" type="primary">
        <router-link to="/">View All</router-link>
    </sdButton>
</sdPageHeader>
<Main>
    <a-row :gutter="15">
        <a-col :xs="24">
            <RecordFormWrapper>
                <sdCards headless>
                    <a-row justify="center">
                        <a-col :xl="10" :md="16" :xs="24">
                            <BasicFormWrapper>
                                <a-form
                                    class="add-record-form"
                                    :style="{ width: '100%' }"
                                    layout="vertical"
                                    name="addnew"
                                >
                                    <a-form-item name="name" label="Name">
                                        <a-input 
                                        v-model:value="name" 
                                        placeholder="Input Name" />
                                        <span class="error-messages" v-if="v$.name.$error">
                                            {{ v$.name.$errors[0].$message }}
                                        </span>
                                    </a-form-item>

                                    <a-form-item name="email" label="Email">
                                        <a-input 
                                        v-model:value="email" 
                                        placeholder="Input Email" />
                                        <span class="error-messages" v-if="v$.email.$error">
                                            {{ v$.email.$errors[0].$message }}
                                        </span>
                                    </a-form-item>

                                    <a-form-item name="password" label="Password">
                                        <a-input 
                                        type="password" 
                                        v-model:value="password" 
                                        placeholder="Input Password" />
                                        <span class="error-messages" v-if="v$.password.$error">
                                            {{ v$.password.$errors[0].$message }}
                                        </span>
                                    </a-form-item>

                                    <a-form-item name="password_confirmation" label="Password Confirmation">
                                        <a-input 
                                        type="password" 
                                        v-model:value="passwordConfirmation" 
                                        placeholder="Input Password Confirmation" />
                                        <span class="error-messages" v-if="v$.passwordConfirmation.$error">
                                            {{ v$.passwordConfirmation.$errors[0].$message }}
                                        </span>
                                    </a-form-item>

                                    <a-form-item name="phone_number" label="Phone">
                                        <a-input 
                                        v-model:value="phoneNumber" 
                                        placeholder="Input Phone Number" />
                                        <span class="error-messages" v-if="v$.phoneNumber.$error">
                                            {{ v$.phoneNumber.$errors[0].$message }}
                                        </span>
                                    </a-form-item>

                                    <div class="record-form-actions text-right">
                                    <sdButton 
                                        size="default" 
                                        htmlType="Save" 
                                        type="primary"
                                        @click="createUser"
                                    >
                                        {{ 'Submit' }}
                                    </sdButton>
                                    </div>
                                </a-form>
                            </BasicFormWrapper>
                        </a-col>
                    </a-row>
                </sdCards>
            </RecordFormWrapper>
        </a-col>
    </a-row>
</Main>
</template>
<script>
import { RecordFormWrapper } from './style';
import { Main, BasicFormWrapper } from '../styled';
import { computed, ref, defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, email, minLength, sameAs } from '@vuelidate/validators';
import UserService from '../../services/UserService';
import { useRouter } from 'vue-router';
  
export default defineComponent({
    name: 'CreateUserComponent',
    
    components: { RecordFormWrapper, Main, BasicFormWrapper },

    setup() {
        const router = useRouter();

        const name = ref(null);
        const email = ref (null);
        const phoneNumber = ref(null);
        const password = ref(null);
        const passwordConfirmation = ref(null);

        const rules = computed(() => ({
            name: {
                required: helpers.withMessage(
                    "The name is required",
                    required
                )
            },
            email: {
                required: helpers.withMessage(
                    "The email is required",
                    required
                ),
                email: helpers.withMessage(
                    "The field email is not valid", 
                    email
                )
            },
            phoneNumber: {
                required: helpers.withMessage(
                    "The phone number is required",
                    required
                )
            },
            password: {
                required: helpers.withMessage(
                    "The field password is required", 
                    required
                ),
                minLength: helpers.withMessage(
                    "The minimum number of characters is 8",
                    minLength(8)
                )
            },

            passwordConfirmation: {
                required: helpers.withMessage(
                    "The field confirm password is required",
                    required
                ),
                minLength: helpers.withMessage(
                    "The minimum number of characters is 8",
                    minLength(8)
                ),
                sameAs: helpers.withMessage(
                    "Passwords do not match",
                    sameAs(password)
                )
            },
        }));

        const createUser = async () => {
            if (! await v$._value.$validate()) return;

            const data = {
                name: name.value,
                email: email.value,
                password: password.value,
                password_confirmation: passwordConfirmation.value,
                phone_number: phoneNumber.value
            }

            await UserService.createUser(data);

            await window.showAlert(
                "success",
                "Success",
                "User created successfully!"
            );

            router.push('/users');
        }

        const v$ = useVuelidate(rules, { name, email, phoneNumber, password, passwordConfirmation });
      
        return {
            name,
            email,
            phoneNumber,
            password,
            passwordConfirmation,
            isLoading,
            v$,
            createUser
        };
    },
  });

</script>
  