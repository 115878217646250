export default {
    generateForgotPasswordToken(email) {
        return window.api.post('/generate_forgot_password_token', {
            email: email
        });
    },

    checkIfResetPasswordTokenExist(token) {
        return window.api.get(`/check_if_fg_token_password_exist?token=${token}`);
    },

    resetPasswordWithToken(data) {
        return window.api.post('/reset_password', data);
    },

    getUserDetails() {
        return window.api.get('/me');
    },

    getUsers () {
        return window.api.get('/users');
    },

    createUser (data) {
        return window.api.post('/users/create', data);
    },

    getUserOne (id) {
        return window.api.get(`/users/one/${id}`);
    },

    updateUser (data, id) {
        return window.api.put(`/users/update/${id}`, data);
    },

    deleteUser (id) {
        return window.api.delete(`/users/delete/${id}`);
    }
}