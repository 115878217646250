<template>
  <a-row :gutter="15">
    <a-col class="w-100" :md="24">
      <sdCards headless>
        <div v-if="isLoading" class="spin">
          <a-spin />
        </div>

        <div v-else>
          <TableWrapper class="table-data-view table-responsive">
            <a-table
              :pagination="{ pageSize: 10, showSizeChanger: true }"
              :dataSource="dataSource"
              :columns="columns"
            />
          </TableWrapper>
        </div>
      </sdCards>
    </a-col>
  </a-row>
  </template>
<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref, defineComponent } from 'vue';
import { TableWrapper } from '../styled';
import UserService from '../../services/UserService';
import moment from 'moment';
  
const columns = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Phone number',
    dataIndex: 'phone_number',
    key: 'phone_number',
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    title: 'Updated',
    dataIndex: 'updated_at',
    key: 'updated_at',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
  
export default defineComponent({
  name: 'UsersComponent',

  components: { TableWrapper },

  setup() {
    const { state, dispatch } = useStore();

    const isLoading = computed(() => state.actions.isLoading);

    const users = ref([]);

    onMounted(async () => {
      await getUsers();
    });

    const getUsers = async () => {
      dispatch("actions/setLoading", true);

      const response = (await UserService.getUsers()).data;
      users.value = response.users;
      dispatch("actions/setLoading", false);
    }

    const handleDelete = async (id) => {
      const confirm = window.confirm('Are you sure delete this?');
      if (confirm) {
        await UserService.deleteUser(id);
        
        await getUsers();

        await window.showAlert(
          "success",
          "Success",
          "User deleted successfully!"
        );
      }
      return false;
    };

    const dataSource = computed(() =>
      users.value.map((user, key) => {
        return {
          key: key + 1,
          name: (
            <div class="record-img align-center-v">
              <span>
                <span>{user.name}</span>
              </span>
            </div>
          ),
          email: user.email,
          phone_number: user.phone_number,
          created_at: moment(user.createdAt).format('YYYY-MM-DD'),
          updated_at: moment(user.createdAt).format('YYYY-MM-DD'),
          action: (
            <div class="table-actions">
              <router-link v-if="user.authorId === null" class="edit" to={`/users/edit/${user.id}`}>
                <sdFeatherIcons type="edit" size={14} />
              </router-link>
              &nbsp;&nbsp;&nbsp;
              <router-link v-if="user.authorId === null" class="delete" onClick={() => handleDelete(user.id)} to="#">
                <sdFeatherIcons type="trash-2" size={14} />
              </router-link>
            </div>
          ),
        };
      })
    );

    return {
      isLoading,
      handleDelete,
      dataSource,
      columns,
    };
  },
});
  
</script>
  