<template>
    <RecordViewWrapper>
        <sdPageHeader title="Users">
        <template #subTitle>
            <sdButton class="btn-add_new" size="default" type="primary">
                <router-link to="/users/create"> <sdFeatherIcons type="plus" size="14" /> Add New </router-link>
            </sdButton>
            <sdButton class="btn-add_new" size="default" key="1" type="warning">
                <router-link :to="{ name: 'users_edit', params: {id: state.auth.userDetails.id} }"> <sdFeatherIcons type="plus" size="14" /> Edit my info </router-link>
            </sdButton>
        </template>
        </sdPageHeader>
        <Main>
            <UsersComponent />
        </Main>
    </RecordViewWrapper>
</template>
<script>
import { Main } from '../styled';
import { RecordViewWrapper } from './style';
import UsersComponent from './UsersComponent.vue'; 
import { useStore } from 'vuex';

export default {
  components: {
    UsersComponent,
    Main,
    RecordViewWrapper,
    UsersComponent
  },
  setup() {
    const { state } = useStore();

    return {
        state
    }
  }
}
</script>